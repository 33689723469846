.container {
  position: relative;
  width: 100%;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  padding-top: 2rem;
  color: #282c34;
}

.content {
  padding-top: 2.5rem;
  padding-bottom: 4.0rem;
}

.header {
  height: 2.25rem;
}

.footer {
  height: 2.25rem;
}

.card {
  width: 18rem;
  text-align: right;
}

.big-card {
  width: 36rem;
  text-align: right;
}

.display-linebreak {
  white-space: pre-line;
  text-align: right;
}

.star {
  color: #e80c7a;
}

.searchbox {
  padding-left: 2.5rem;
  padding-right: 2.5rem;
}